export const features = [
  "Balcon/Terrasse",
  "Stationnement",
  "Piscine",
  "Neuf",
  "Jardin",
  "Ascenseur",
  "Proche des écoles",
  "Proche des commerces",
  "Cuisine ouverte",
  "Vue sur la mer",
  "Vue sur la montagne",
];

export const applicableFeatures = ["maison", "villa", "appartement"];
