import React from "react";
import styles from "./Phone.module.css"; // Import css modules stylesheet as styles
import { IoCloseCircle } from "react-icons/io5";
import { FaPhone } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

const PhoneModal = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <IoCloseCircle className={styles.deleteImage} onClick={onClose} />
        <h5>{t("callNow")}</h5>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            marginTop: "1rem",
          }}
        >
          <a
            href='tel:+216 22 307 549'
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.3rem",
                border: "1px solid #B3C0C0",
                marginLeft: "1rem",
                marginRight: "1rem",
                borderRadius: "5px",
              }}
            >
              <FaPhone />
              <span>+216 22 307 549</span>
            </div>
          </a>
          <a
            href='tel:+216 20 532 181'
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.3rem",
                border: "1px solid #B3C0C0",
                marginLeft: "1rem",
                marginRight: "1rem",
                borderRadius: "5px",
              }}
            >
              <FaPhone />
              <span>+216 20 532 181</span>
            </div>
          </a>
        </div>

        {/* <button onClick={onClose}>Close</button> */}
      </div>
    </div>
  );
};

export default PhoneModal;
