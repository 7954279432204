export const ammeublementOptions = [
  {
    value: "",
    label: "-",
  },
  {
    value: "meuble",
    label: "Meublé",
  },
  {
    value: "nonMeuble",
    label: "Non meublé",
  },
  {
    value: "partiellementMeuble",
    label: "Partiellement meublé",
  },
];
