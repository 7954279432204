import React from "react";
import phone from "../assets/phone.svg";
import whatsapp from "../assets/whatsapp.svg";
import email from "../assets/email.svg";
import { useState } from "react";
import PhoneModal from "./PhoneModal";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ContactItem = ({ propIdName }) => {
  const { t } = useTranslation();
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const history = useHistory();

  const message = `${t("propertyMessageInfo")} ${
    process.env.REACT_APP_WEBSITE_URL
  }/bien/details/${propIdName}`;
  const handleEmailClick = () => {
    history.push({
      pathname: "/contact",
      state: { propertyMessage: message },
    });
  };

  const handlePhoneClick = () => {
    setShowPhoneModal(true);
  };

  const handleWhatsAppClick = () => {
    const phoneNumber = "+21622307549";

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <>
      <div
        style={{
          borderTop: "1px solid #B3C0C0",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <div
            onClick={handleEmailClick}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              paddingTop: "0.3rem",
              paddingBottom: "0.3rem",
            }}
          >
            <img src={email} alt='email' style={{ marginRight: "0.3rem" }} />
            <span
              style={{ color: "#030303", fontWeight: "500", fontSize: "15px" }}
            >
              Email
            </span>
          </div>
          <div style={{ borderLeft: "1px solid #B3C0C0" }}></div>

          <div
            onClick={handlePhoneClick}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              paddingTop: "0.3rem",
              paddingBottom: "0.3rem",
            }}
          >
            <img src={phone} alt='phone' style={{ marginRight: "0.3rem" }} />
            <span
              style={{ color: "#D71430", fontWeight: "500", fontSize: "15px" }}
            >
              Phone
            </span>
          </div>
          <div style={{ borderLeft: "1px solid #B3C0C0" }}></div>

          <div
            onClick={handleWhatsAppClick}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              paddingTop: "0.3rem",
              paddingBottom: "0.3rem",
            }}
          >
            <img
              src={whatsapp}
              alt='whatsapp'
              style={{ marginRight: "0.3rem" }}
            />
            <span
              style={{ color: "#5FD568", fontWeight: "500", fontSize: "15px" }}
            >
              WhatsApp
            </span>
          </div>
        </div>
      </div>
      {showPhoneModal && (
        <PhoneModal onClose={() => setShowPhoneModal(false)} />
      )}
    </>
  );
};

export default ContactItem;
